var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"superWideRef",staticClass:"super-wide",style:(_vm.isMoving || _vm.complateMove ? {} : {
    overflow: 'hidden',
    width: '100%',
  }),attrs:{"dir":_vm.isLeft ? 'ltr' : 'rtl'},on:{"touchstart":_vm.handleImgStop,"click":_vm.handleImgClick}},[_c('div',{ref:"superWideWrapperRef",staticClass:"super-wide__wrapper",style:([_vm.calcMaxWide, _vm.movingStyle]),on:{"transitionend":_vm.handleImgStop}},[_c('InvokeApp',{staticStyle:{"width":"100%","height":"100%"},attrs:{"deeplink-source":_vm.item.hrefTarget,"is-direct-store":true,"is-deeplink-style-string":true,"is-invoke":_vm.item.hrefTarget?.indexOf?.('sheinlink') > -1}},[(_vm.item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getAnalysisData('2-22-2', _vm.handleAnalysisData(_vm.item, _vm.index))),expression:"getAnalysisData('2-22-2', handleAnalysisData(item, index))"},{name:"expose",rawName:"v-expose",value:(_vm.getAnalysisData('2-22-1', _vm.handleAnalysisData(_vm.item, _vm.index))),expression:"getAnalysisData('2-22-1', handleAnalysisData(item, index))"}],staticClass:"fsp-element",attrs:{"placeholder":{
          width: _vm.item.image.width,
          height: _vm.item.image.height
        },"fit":"initial","ratio":_vm.item.image.ratio,"img-src":_vm.item.image.src,"first-screen":_vm.propData?.isFirstPage,"ada":_vm.item.ada,"alt":_vm.item.hrefTitle,"is-support-webp":_vm.propData.isSupportWebp,"img-design-width":_vm.calcWideValue < 375 ? 750 : _vm.calcWideValue * 2}}):_vm._e()],1),_vm._v(" "),(_vm.showHotZone)?_c('HotZone',{attrs:{"index":_vm.index,"context":_vm.context,"language":_vm.context.language,"prop-data":_vm.propData,"scene-data":_vm.sceneData,"cate-links":_vm.cateLinks,"get-analysis-data":_vm.getAnalysisData,"dialog-append-body":true},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }