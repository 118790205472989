<template>
  <div
    ref="float-image-container"
    :class="[
      'float-image',
      intervalSpacing ? 'float-image__interval-spacing' : '',
    ]"
    @click="handleFloatClick"
  >
    <template v-for="(item, index) in imageList">
      <BaseImg
        v-if="item.image"
        :key="index"
        :ref="`float-image-${index}`"
        v-tap="getAnalysisData('2-22-2', handleAnalysisData(item, index))"
        v-expose="getAnalysisData('2-22-1', handleAnalysisData(item, index))"
        :placeholder="{
          width: item.image.width,
          height: item.image.height,
        }"
        :fit="originImageSize(item.image) ? 'none' : 'cover'"
        :ratio="item.image.ratio"
        :img-src="item.image.src"
        :first-screen="propData?.isFirstPage"
        :img-design-width="750"
        :ada="item.ada"
        :alt="item.hrefTitle"
        :is-support-webp="propData.isSupportWebp"
        :class="[
          'float-image__img fsp-element',
          index === 1 ? 'float-image__img_float' : '',
          originImageSize(item.image) ? 'float-image__img_ogirin' : '',
        ]"
        :style="index === 1 ? floatImageStyle : {}"
      />
    </template>
  </div>
</template>

<script>
import BaseImg from '../../base/BaseImg.vue'

import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { Scroll } from '@shein-aidc/basis-expose'

const UPPER_RATIO = 0.5 // 悬浮距上图比例
const LOWER_RATIO = 0.35 // 悬浮距下图比例

export default {
  name: 'FloatStyle',
  components: {
    BaseImg,
  },
  directives: { tap, expose },
  mixins: [mixins],
  props: commonProps,
  data() {
    const { props = {} } = this.propData || {}
    const { items = [], metaData = {} } = props
    const { intervalSpacing = false, hrefConfig = {} } = metaData
    // 后台固定3张图：0：上图，1：悬浮图，2：下图
    const imageList = items.length === 3 ? items : []
    return {
      imageList,
      hrefConfig, // 悬浮图跳转配置
      intervalSpacing,
      floatImageStyle: {},
    }
  },
  computed: {
    // case1: 宽高均小于等于255*70时展示原尺寸
    // case2: 宽高任何一边超出255*70时，按照cover展示（超出裁切）
    originImageSize() {
      return ({ width = 0, height = 0 }) => {
        return Number(width) <= 255 && Number(height) <= 70
      }
    },
  },
  created() {
    this.initFloatLocation()
  },
  mounted() {
    this.handleScroll()
  },
  deactivated() {
    this.viewScroll.unListen(this.listenId)
  },
  methods: {
    // 初始化悬浮图位置
    initFloatLocation() {
      const intervalSpace = this.intervalSpacing ? 24 : 0
      const defaultBase = 375 - intervalSpace // 以375宽度为基准，减去间距(因为配置返回的宽高不一定是375宽度基准)
      if (this.imageList.length !== 3) return
      const sameBaseImageHeightList = this.imageList.map((item) => {
        const { image = {} } = item
        const { width = 0, height = 0 } = image
        return (defaultBase / width) * height
      })
      const percent =
        (sameBaseImageHeightList[0] * UPPER_RATIO) /
        (sameBaseImageHeightList[0] + sameBaseImageHeightList[2])
      this.floatImageStyle = {
        position: 'absolute',
        top: `${percent * 100}%`,
      }
    },
    handleScroll() {
      const floatImageContainer = this.$refs['float-image-container']
      const upperImage = this.$refs['float-image-0']?.[0]
      const lowerImage = this.$refs['float-image-2']?.[0]
      const upperImageH = upperImage.getBoundingClientRect()?.height
      const lowerImageH = lowerImage.getBoundingClientRect()?.height
      // fixed时距离顶部距离（蒙层默认位置时距上图顶部距离）
      const fixedDistance = upperImageH * UPPER_RATIO
      // 下图顶部指定位置（上图高度 + 距下图顶部距离）
      const lowerTopDistance = upperImageH + lowerImageH * LOWER_RATIO

      this.listenId = void 0
      this.viewScroll = new Scroll((entries) => {
        // 进入视口再监听
        if (entries[0].isIntersecting) {
          const scrollCb = (params) => {
            // 获取目标容器实际触顶距离
            const containerTop =
              floatImageContainer.getBoundingClientRect().top + params.scrollTop
            // 目标容器未触root顶时
            if (params.scrollTop - containerTop < 0) {
              this.floatImageStyle = {
                position: 'absolute',
                top: `${fixedDistance}px`,
              }
              return
            }

            // 进入目标容器且未抵达【下图顶部指定位置】时
            if (
              params.scrollTop - containerTop <
              lowerTopDistance - fixedDistance
            ) {
              this.floatImageStyle = {
                position: 'fixed',
                top: `${fixedDistance}px`,
              }
            } else {
              this.floatImageStyle = {
                position: 'absolute',
                top: `${lowerTopDistance}px`,
              }
            }
          }
          this.listenId = this.viewScroll.listen(scrollCb, {
            delay: 0, // 防抖时间
          })
          if (typeof window !== 'undefined') window.scroll() // 触发一次滚动更新
        } else {
          // 离开视口则取消监听
          this.viewScroll.unListen(this.listenId)
        }
      })
      this.viewScroll.observe(floatImageContainer) // 指定目标容器
      this.$once('hook:beforeDestroy', () => {
        if (!this.viewScroll) return
        this.viewScroll.unListen(this.listenId)
        this.viewScroll.disconnect()
        this.viewScroll.unobserve(floatImageContainer)
      })
    },
    handleFloatClick() {
      this.$emit(
        'click',
        {
          ...this.imageList[0],
          ...this.hrefConfig,
        },
        0
      )
    },
    /**
     * 埋点参数处理
     */
    handleAnalysisData(item, index) {
      return {
        item: {
          ...item,
          ...this.hrefConfig,
        },
        index,
        useBffApi: true, // 使用bff接口提供的基础埋点参数
      }
    },
  },
}
</script>

<style lang="less" scoped>
@rem: 37.5rem;

.float-image {
  position: relative;
  width: 100%;
  overflow: hidden;

  &__interval-spacing {
    margin: 0 12 / @rem;
  }

  &__img_float {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 50%;
    right: auto;
    z-index: @zindex-hack;
    width: 255 / @rem;
    height: 70 / @rem;
    transform: translateX(-50%);

    /deep/ .base-img__inner {
      position: absolute;
      top: 50%;
      bottom: auto;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
      width: auto;
      min-height: 100%;
    }
  }

  &__img_ogirin {
    /deep/ .base-img__inner {
      object-fit: none; // baseImg中设none其实为默认值fill，手动设置none展示原尺寸
    }
  }
}
</style>
