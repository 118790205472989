var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"float-image-container",class:[
    'float-image',
    _vm.intervalSpacing ? 'float-image__interval-spacing' : '',
  ],on:{"click":_vm.handleFloatClick}},[_vm._l((_vm.imageList),function(item,index){return [(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getAnalysisData('2-22-2', _vm.handleAnalysisData(item, index))),expression:"getAnalysisData('2-22-2', handleAnalysisData(item, index))"},{name:"expose",rawName:"v-expose",value:(_vm.getAnalysisData('2-22-1', _vm.handleAnalysisData(item, index))),expression:"getAnalysisData('2-22-1', handleAnalysisData(item, index))"}],key:index,ref:`float-image-${index}`,refInFor:true,class:[
        'float-image__img fsp-element',
        index === 1 ? 'float-image__img_float' : '',
        _vm.originImageSize(item.image) ? 'float-image__img_ogirin' : '',
      ],style:(index === 1 ? _vm.floatImageStyle : {}),attrs:{"placeholder":{
        width: item.image.width,
        height: item.image.height,
      },"fit":_vm.originImageSize(item.image) ? 'none' : 'cover',"ratio":item.image.ratio,"img-src":item.image.src,"first-screen":_vm.propData?.isFirstPage,"img-design-width":750,"ada":item.ada,"alt":item.hrefTitle,"is-support-webp":_vm.propData.isSupportWebp}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }